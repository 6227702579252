import React from "react";
import Header from "../components/header/header.js";
import { useForm, ValidationError } from '@formspree/react';
import "../assets/styles/pages/_contacts.scss";

const Contacts = () => {

  const [state, handleSubmit] = useForm(process.env.GATSBY_FORM_SPREE);

  return (
    <>
      <Header title="Contatti" />
      <div className="container contacts">
        <div className="columns">
          <div className="column">Dove trovarci</div>
          <div className="column">
          Sede Legale Circonvallazione Clodia, 163/171 <br /> 
          00195  <br />
          Roma <br />
          +39 06 21128365           
          </div>
        </div>
        <div className="line" />
        <div className="columns">
          <div className="column">Email</div>
          <div className="column"><a href= "mailto:info@connectatyou.it">info@connectatyou.it</a></div>
        </div>
        <div className="line" />
        <div className="columns">
          <div className="column">Richiedi informazioni</div>
          <div className="column">
            {  ( state.succeeded) ?  <p className="contact-form-success">Messaggio inviato con successo! Sarai ricontattato a breve.</p>
              :
            <form onSubmit={handleSubmit}>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Nome</span>
                    <br />
                    <input
                      name="name" className="input" type="text" placeholder="Nome" required />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Email</span>
                    <br />
                    <input
                      name="email" className="input" type="email" placeholder="Email" required/>

                      <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                      />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Telefono</span>
                    <br />
                    <input
                      name="telephone" className="input" type="text" placeholder="Telefono" required/>

                      <ValidationError 
                        prefix="Telefono" 
                        field="telephone"
                        errors={state.errors}
                      />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-10">
                  <div className="inputBox">
                    <span className="azure">Attività</span>
                    <br />
                    <input 
                      name="business" className="input" type="text" placeholder="Attività" />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-8">
                  <div className="inputBox">
                    <span className="azure">Comune</span>
                    <br />
                    <input
                      name="city" 
                      className="input" 
                      type="text" 
                      placeholder="Comune" />
                  </div>
                </div>               
              </div>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Messaggio</span>
                    <br />
                    <textarea
                      style={{height: 100}}
                      name="message"
                      className="input"
                      type="text"
                      placeholder="Scrivi un messaggio"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <label className="checkbox">
                    <input type="checkbox" required />
                    <span>Accetto i Termini e Condizioni</span>
                  </label>
                </div>
                <div className="column sendMessage">
                  <button className="button"  type="submit" disabled={state.submitting}>Invia messaggio</button>
                </div>
              </div>
            </form> }
            
          </div>
          
        </div>
        <div className="line" />
      </div>
    </>
  );
};

export default Contacts;
